import React from 'react';
import Img from "../../../components/Img";
import {Link} from "gatsby";
import {BigPlayButton, ControlBar, Player} from "video-react";
import classNames from 'classnames';

const PortfolioItem = ({name, detailLink, priority, img, imgTitle, video}) => {
    return (
            <div className={classNames("portfolio-item", {
                'portfolio-item_grid_priority': priority
            })}>
                <Link to={detailLink} className="portfolio-item-link">
                    <h3 className="subtitle portfolio-item__name">
                        {name}
                    </h3>
                    <div className="portfolio-item-hover">
                        <Player muted={true}
                                loop
                                autobuffer
                                autoPlay
                                fluid={true}
                                height="100%"
                                width="100%"
                                src={video}
                                playsInline={true}
                        >
                            <ControlBar autoHide={false} disableDefaultControls={true} disableCompletely={true}/>
                            <BigPlayButton position="center" />
                        </Player>
                    </div>


                    <div className="portfolio-item-img">
                        <Img fluid={img} alt={imgTitle} className="portfolio-item-img__item"/>
                    </div>
                </Link>
            </div>

    );
};


export default PortfolioItem;
