import React, {useState, useEffect} from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";

import {setPortfolio} from "../../../redux/reducers/portfolioReducer";
import PortfolioItem from '../components/PortfolioItems';


const PortfolioContainer = ({languageSelect, setPortfolio, portfolioElements, portfolioElementsFilters}) => {

    const [portfolio, setPortfolioLoad] = useState([]);
    const [portfolioVisable, setPortfolioVisable] = useState(9);

    const loadMore = () => {
        setPortfolioVisable(portfolioVisable + 9);
    };

    useEffect(() => {
        setPortfolioLoad(portfolioElementsFilters);
    });

    return (
        <StaticQuery
            query={graphql`
              query  {
                  allContentfulPortfolioItems {
                    edges {
                      node {
                        id
                        contentful_id
                        priority
                        img {
                          title
                          gatsbyImageData
                        }
                        subtitle_ru
                        subtitle_en
                        subtitle_cn
                        videoShort {
                          file {
                            url
                          }
                        }
                      }
                    }
                  }
                  
              }
            `}
            render={data => {

                const loadMoreName = () => {
                    switch (languageSelect) {
                        case 'ru':
                            return 'Показать ещё';
                        case 'en':
                            return 'Load more';
                        case 'cn':
                            return '顯示更多'
                    }
                };

                if (portfolioElements.length <= 0) {
                    setPortfolio(data.allContentfulPortfolioItems.edges);
                }


                const portfolioItems = portfolio.slice(0, portfolioVisable).map((item) => {
                    return (
                        <PortfolioItem key={item.node.id}
                                       name={item.node["subtitle_" + languageSelect]}
                                       detailLink={'/portfolio/' + item.node.subtitle_en.toLocaleLowerCase().replace(/ /g, '-')}
                                       priority={item.node.priority}
                                       img={item.node.img}
                                       imgTitle={item.node.img.title}
                                       video={item.node.videoShort.file.url}
                        />
                    );
                });

                return (
                    <section className="section section-portfolio">
                        <div className="container">
                            <div className="portfolio">
                                {portfolioItems}
                            </div>
                            {
                                portfolioVisable < portfolio.length &&
                                <div className="load-more">

                                    <button className="button-detail-link" onClick={() => loadMore()}>
                                        <span className="button-detail-link-hover button-detail-link-hover_bottom"></span>
                                        <span className="button-detail-link-hover button-detail-link-hover_top"></span>
                                        {loadMoreName()}
                                    </button>
                                </div>
                            }
                        </div>
                    </section>
                );
            }}
        />


    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
        portfolioElements: state.portfolio["portfolio-list"],
        portfolioElementsFilters: state.portfolio["portfolio-list-filter"],
    }
};

export default connect(mapStateToProps, {setPortfolio})(PortfolioContainer);
